import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const FacebookIcon = ({ authorName, facebook }) => {
    return (
        <a
            css={css(linkStyle)}
            href={`https://www.facebook.com/${facebook}`}
            target="_blank"
            rel="noopener noreferrer"
            alt={authorName}
        >
            <img src="/facebook.png" css={css(iconStyle)} alt="facebook" />
        </a>
    );
};

FacebookIcon.propTypes = {
    authorName: PropTypes.string,
    facebook: PropTypes.string,
};

export default FacebookIcon;

const linkStyle = {
    boxShadow: 'none',
    margin: '0 5px',
};

const iconStyle = {
    width: '50px',
    '& path': {
        fill: '#7ba3ff !important',
    },
};
