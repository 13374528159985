import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import { rhythm } from '../utils/typography';

import Header from './Header';
import SocialList from './SocialComponent/SocialList';

const Layout = ({ title, children, social, author, isModal }) => {
    return (
        <div
            css={css(
                isModal
                    ? ''
                    : {
                          marginLeft: `auto`,
                          marginRight: `auto`,
                          maxWidth: rhythm(40),
                          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                      }
            )}
        >
            {!isModal && (
                <>
                    {social && <SocialList social={social} author={author} />}
                    <header>
                        <Header title={title} />
                    </header>
                </>
            )}
            <main>{children}</main>
            {!isModal && <footer>Linda Rich © {new Date().getFullYear()}</footer>}
        </div>
    );
};

Layout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    social: PropTypes.object,
    author: PropTypes.object,
};

export default Layout;
