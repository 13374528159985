import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { css } from '@emotion/core';

const Header = ({ title }) => {
    return (
        <header>
            <h3
                css={css({
                    fontFamily: `Montserrat, sans-serif`,
                    marginTop: 0,
                })}
            >
                <Link css={css(headerItemStyle)} to={`/`}>
                    {title}
                </Link>
                <Link css={css(headerItemStyle)} to="/about">
                    About
                </Link>
            </h3>
        </header>
    );
};

Header.propTypes = {
    title: PropTypes.string,
};

export default Header;

const headerItemStyle = {
    boxShadow: `none`,
    color: `inherit`,
    margin: '0 10px',
};
