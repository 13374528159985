import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Facebook from './FacebookIcon';
import InstagramIcon from './InstagramIcon';

const SocialList = ({ author, social }) => {
    const { name } = author;
    const { facebook, instagram } = social;
    return (
        <div css={css(cotainerStyle)}>
            {instagram && <InstagramIcon authorName={name} instagram={instagram} />}
            {facebook && <Facebook authorName={name} facebook={facebook} />}
        </div>
    );
};

SocialList.propTypes = {
    author: PropTypes.object,
    social: PropTypes.object,
};

export default SocialList;

const cotainerStyle = {
    float: 'right',
};
