import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const InstagramIcon = ({ authorName, instagram }) => {
    return (
        <a
            css={css(linkStyle)}
            href={`https://www.instagram.com/${instagram}`}
            target="_blank"
            rel="noopener noreferrer"
            alt={authorName}
        >
            <img src="/instagram.png" css={css(iconStyle)} alt="facebook" />
        </a>
    );
};

InstagramIcon.propTypes = {
    authorName: PropTypes.string,
    instagram: PropTypes.string,
};

export default InstagramIcon;

const linkStyle = {
    boxShadow: 'none',
    margin: '0 5px',
};

const iconStyle = {
    width: '50px',
};
